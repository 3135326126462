<template>
  <div class="row no-gutters registrazione">
    <div class="col-10 offset-1">
      <div class="row no-gutters">
        <div class="col-lg-5 order-1">
          <app-registration :registrationData="registration">
          </app-registration>
        </div>
        <div class="col-lg-6 offset-lg-1 order-2">
          <app-registation-step></app-registation-step>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppRegistationStep from "../components/organisms/AppRegistationStep.vue";
import AppRegistration from "../components/organisms/AppRegistration.vue";

export default {
  components: { AppRegistationStep, AppRegistration },
  name: "registrazione",

  data() {
    return {
      message: "",

      registration: {
        firstName: null,
        lastName: null,
        codiceFiscale: null,
        straniero: false,
        nocodes:false,
        birthDate: "ND",
        gender: "ND",
        country: "ND",
        nationBorn: "ND",
        cityBornFromTaxCode: "ND",
        username: null,
        password: null,
        rePassword: null,
        email: null,
        reEmail: null,
        tel: null,
        invoice: {
          data: {},
        },
        nation: null,
        province: null,
        privacy: null,
        privacy2: null,
        privacy3: null,
        privacy4: null,
        privacy5: null,
        privacy6: null,
        privacy7: null,
        team: "",
        stp: "",
        all: false,
      },
    };
  },

  methods: {
    /*  saveRegistration(data) {
      console.log(data);
      this.registration = data;
    }, */
  },
};
</script>
