<template>

  <div class="o-registration-collapse">

    <div class="accordion" role="tablist">
      <div block :class="funtionCollapseStates(0)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{ 'o-registration-collapse__hide': !showCheck[0] }">
          <span class="icon-check"></span>
        </div>
        {{ $t("step.anagrafica") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[0] }" v-b-toggle="['accordion-anagrafici']"
          :disabled="showPen[0] !== true"></button>
      </div>
      <b-collapse id="accordion-anagrafici" visible accordion="my-anagrafici" role="tabpanel"
        class="o-registration-collapse__form" v-model="collapseStates[0]">
        <b-form-group id="firstName" :label="this.$t('step.firstname')" label-for="firstName-input" class="m-input">
          <b-form-input id="firstName-input" v-model="registration.firstName" type="text"
            :state="/*isName(registration.firstName) &&*/ isNameValid(registration.firstName, registration.codiceFiscale)"
            :placeholder="this.$t('step.placeholder_firstname')" required></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_nome") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lastName" :label="this.$t('step.lastName')" label-for="lastName-input" class="m-input">
          <b-form-input id="lastName-input" v-model="registration.lastName" type="text"
            :state="/*isName(registration.lastName) &&*/ isSurnameValid(registration.lastName, registration.codiceFiscale)"
            :placeholder="this.$t('step.placeholder_lastName')" required></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_cognome") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="codiceFiscale" :label="this.$t('step.cf')" label-for="codiceFiscale-input" class="m-input">
          <b-form-input id="codiceFiscale-input" v-model="registration.codiceFiscale" :state="codiceFiscaleState"
            :disabled="registration.straniero === 'true'" type="text" :placeholder="this.$t('step.placeholder_cf')"
            required></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_cf") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-checkbox id="checkbox" v-model="registration.straniero" :disabled="
          registration.codiceFiscale !== null &&
          registration.codiceFiscale !== ''
        " name="checkbox" value="true" unchecked-value="false" disabled-value="true" class="pt-1 pb-3">
          {{ $t("step.no_cf") }}
        </b-form-checkbox>
        <b-form-group id="team" :label="this.$t('registration.team')" label-for="team-input" class="m-input"
          v-if="registration.straniero === 'true'">
          <b-form-input id="team-input" v-model="registration.team" type="text" :state="isTeam(registration.team)"
            :disabled="registration.stp !== '' || registration.nocodes === 'true'"
            :placeholder="this.$t('registration.placeholder_team')"></b-form-input>
        </b-form-group>
        <b-form-group id="stp" :label="this.$t('registration.stp')" label-for="stp-input" class="m-input"
          v-if="registration.straniero === 'true'">
          <b-form-input id="stp-input" v-model="registration.stp" type="text" :state="isSTP(registration.stp)"
            :disabled="registration.team !== '' || registration.nocodes === 'true'"
            :placeholder="this.$t('registration.placeholder_stp')"></b-form-input>
        </b-form-group>
        <b-form-checkbox v-if="registration.straniero === 'true'" id="checkbox_nocodes" v-model="registration.nocodes"
          :disabled="
            (registration.straniero === 'false' ||
              registration.team !== '' ||
              registration.stp !== '')
          " name="checkbox" value="true" unchecked-value="false" class="pt-1 pb-3">
          {{ $t("step.no_codes") }}
        </b-form-checkbox>
        <b-form-group id="birthdate" :label="this.$t('registration.birthdate')" label-for="birthdate-input"
          class="m-input" v-if="registration.straniero === 'true'">
          <b-form-input id="birthdate-input" v-model="registration.birthDate" type="date"
            :placeholder="this.$t('registration.placeholder_birthdate')"></b-form-input>
        </b-form-group>
        <b-form-group id="gender" :label="this.$t('registration.gender')" label-for="gender-input" class="m-input"
          v-if="registration.straniero === 'true'">
          <b-form-select id="gender-input" v-model="registration.gender" :options="optionsGender"></b-form-select>
        </b-form-group>

        <b-form-group id="nationBorn" :label="this.$t('registration.nation_born')" label-for="nationBorn-input"
          class="m-input" v-if="registration.straniero === 'true'">
          <b-form-select id="nationBorn-input" v-model="registration.nationBorn" :options="optionsNationBorn">
          </b-form-select>
        </b-form-group>

        <!--  <b-form-group
          id="country"
          :label="this.$t('registration.country_born')"
          label-for="country-input"
          class="m-input"
          v-if="registration.straniero === 'true'"
        >
          <b-form-select
            id="country-input"
            v-model="registration.country"
            :options="optionsCountry"
          ></b-form-select>
        </b-form-group> -->

        <!--  <b-form-group
          id="cityBornFromTaxCode"
          :label="this.$t('registration.city_born')"
          label-for="cityBornFromTaxCode-input"
          class="m-input"
          v-if="registration.straniero === 'true'"
        >
          <b-form-select
            id="cityBornFromTaxCode-input"
            v-model="registration.cityBornFromTaxCode"
            :options="optionsCityBornFromTaxCode"
          ></b-form-select>
        </b-form-group> -->

        <div class="o-registration-collapse__autocopilate" v-if="registration.straniero !== 'true'">
          <p>
            <strong>{{ $t("registration.birthdate") }}:</strong>
            {{ registration.birthDate }}
          </p>
          <p>
            <strong>{{ $t("registration.gender") }}:</strong>
            {{ registration.gender }}
          </p>
          <p>
            <strong>{{ $t("registration.nation_born") }}: </strong>{{ getNation(registration.nationBorn).text }}
          </p>
          <p>
            <strong>{{ $t("registration.country_born") }}: </strong>{{ registration.country }}
          </p>
          <p>
            <strong>{{ $t("registration.city_born") }}: </strong>{{ registration.cityBornFromTaxCode }}
          </p>
        </div>
        <div class="o-registration-collapse__action">
          <b-button v-b-toggle="[
            'accordion-anagrafici',
            'accordion-credenziali-accesso',
          ]" :disabled="showCheck[0] !== true" variant="primary" size="md">{{ $t("registration.continua") }}
          </b-button>
        </div>
      </b-collapse>

      <div block :class="funtionCollapseStates(1)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{ 'o-registration-collapse__hide': !showCheck[1] }">
          <span class="icon-check"></span>
        </div>
        {{ $t("step.credenziali") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[1] }"
          v-b-toggle="['accordion-credenziali-accesso']"></button>
      </div>
      <b-collapse id="accordion-credenziali-accesso" accordion="my-credenziali-accesso" role="tabpanel"
        class="o-registration-collapse__form" v-model="collapseStates[1]">
        <b-form-group id="username" label="Username" label-for="username-input" class="m-input">
          <b-form-input id="username-input" v-model="registration.username" type="text"
            :placeholder="this.placeholder_username" :disabled="registration.straniero !== 'true'"></b-form-input>
        </b-form-group>
        <!--     <b-form-group
          id="password"
          :label="this.$t('registration.password')"
          label-for="password-input"
          class="m-input"
        >
          <b-form-input
            id="password-input"
            v-model="registration.password"
            type="password"
            :state="isPassword(registration.password)"
            :placeholder="this.$t('registration.password_placeholder')"
            required
          ></b-form-input>

          <b-form-invalid-feedback>
            {{ $t("step.invalid_password") }}
          </b-form-invalid-feedback>
        </b-form-group> -->
        <b-form-group id="password" :label="this.$t('registration.password')" label-for="password-input"
          class="m-input m-input__password">
          <div class="icon-eye mr-3 m-input__password" @mousedown="chengeTypeInput('password-input')"></div>
          <b-form-input id="password-input" v-model="registration.password" type="password"
            :state="isPassword(registration.password)" :placeholder="this.$t('registration.password_placeholder')"
            aria-describedby="input-feedback-password" required></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_password") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!--  <b-form-group
          id="rePassword"
          :label="this.$t('registration.password_repeat')"
          label-for="rePassword-input"
          class="m-input"
        >
          <b-form-input
            id="rePassword-input"
            v-model="registration.rePassword"
            type="password"
            :state="isPassword(registration.rePassword)"
            :placeholder="this.$t('registration.password_repeat_placeholder')"
            required
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_repassword") }}
          </b-form-invalid-feedback>
        </b-form-group> -->
        <b-form-group id="email" :label="this.$t('registration.email')" label-for="email-input"
          class="m-input m-input__icon">
          <div class="icon-email mr-3"></div>
          <b-form-input id="email-input" v-model="registration.email" type="email" :state="isEmail(registration.email)"
            :placeholder="this.$t('registration.email_placeholder')" aria-describedby="input-feedback-email" required>
          </b-form-input>
        </b-form-group>

        <b-form-group id="reEmail" :label="this.$t('registration.email_repeat')" label-for="reEmail-input"
          class="m-input m-input__icon">
          <div class="icon-email mr-3"></div>
          <b-form-input id="reEmail-input" v-model="registration.reEmail" type="email"
            :state="isEmail(registration.reEmail)" :placeholder="this.$t('registration.email_repeat_placeholder')"
            required></b-form-input>
        </b-form-group>

        <b-form-group id="tel" :label="this.$t('registration.tel')" label-for="tel-input" class="m-input m-input__tel">
          <span class="d-flex w-100">
            <div class="icon-phone-check mr-3"></div>
            <b-form-input id="tel-input" v-model="registration.tel" type="tel" :state="isPhone(registration.tel)"
              :placeholder="this.$t('registration.tel_placeholder')" required></b-form-input>
          </span>

          <b-form-invalid-feedback class="">
            {{ $t("step.invalid_tel") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="o-registration-collapse__action">
          <b-button v-b-toggle="[
            'accordion-credenziali-accesso',
            'accordion-fatturazione',
          ]" :disabled="showCheck[1] !== true" variant="primary" size="md">{{ $t("registration.continua") }}
          </b-button>
        </div>
      </b-collapse>

      <div block :class="funtionCollapseStates(2)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{ 'o-registration-collapse__hide': !showCheck[2] }">
          <span class="icon-check"></span>
        </div>
        {{ $t("step.fatturazione") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[2] }"
          v-b-toggle="['accordion-fatturazione']"></button>
      </div>
      <b-collapse id="accordion-fatturazione" accordion="my-fatturazione" role="tabpanel"
        class="o-registration-collapse__form" v-model="collapseStates[2]">
        <!--
        <b-form-group
            v-if="false" 
            id="ind"
            :label="this.$t('registration.indirizzo_completo')"
            label-for="ind-input"
            class="m-input"
        >
          <places
              v-model="registration.invoice.label"
              :placeholder="
              this.$t('registration.indirizzo_completo_placeholder')
            "
              @change="
              (val) => {
                registration.invoice.data = val;
                registration.invoice.data.oCountry=getNation( val.countryCode==null?'':val.countryCode.toUpperCase());

              }
            "
          >
            >
          </places
          >
        </b-form-group>
        -->

        <b-form-group id="nation" :label="this.$t('registration.nation_billing')" label-for="nation-input"
          class="m-input">
          <b-form-select id="nation-input" v-model="registration.invoice.data.country" :options="optionsNationBorn"
            @change="changeNation(registration.invoice.data.country)"></b-form-select>
        </b-form-group>

        <!--
        <b-form-group
            id="nation"
            :label="this.$t('registration.nation_billing')"
            label-for="nation-input"
            class="m-input"
        >
          <b-form-input
              id="nation-input"
              v-model="registration.invoice.data.country"
              type="text"
              :disabled="indirizzoCheck"
          ></b-form-input>
        </b-form-group>
        -->

        <b-form-group v-if="this.isNazionale" id="province" :label="this.$t('registration.country_billing')"
          label-for="province-input" class="m-input">
          <b-form-select @change="fillComuni(registration.invoice.data.county)" id="province-select"
            v-model="registration.invoice.data.county" :options="optionProvince"></b-form-select>
        </b-form-group>

        <b-form-group v-if="!this.isNazionale" id="province" :label="this.$t('registration.country_billing')"
          label-for="province-input" class="m-input">
          <b-form-input id="province-input" v-model="registration.invoice.data.county" type="text" placeholder=""
            :disabled="indirizzoCheck || !this.isNazionale"></b-form-input>
        </b-form-group>

        <b-form-group v-if="this.isNazionale" id="city" :label="this.$t('registration.city')" label-for="city-input"
          class="m-input">
          <b-form-select id="city-select" v-model="registration.invoice.data.city" :options="optionComuni">
          </b-form-select>
        </b-form-group>

        <b-form-group v-if="!this.isNazionale" id="city" :label="this.$t('registration.city')" label-for="city-input"
          class="m-input">
          <b-form-input id="city-input" v-model="registration.invoice.data.city" type="text" placeholder=""
            :disabled="indirizzoCheck"></b-form-input>
        </b-form-group>

        <b-form-group id="ind" :label="this.$t('registration.address')" label-for="ind-input" class="m-input">
          <b-form-input id="ind-input" v-model="registration.invoice.data.name" type="text" :disabled="indirizzoCheck">
          </b-form-input>
        </b-form-group>
        <b-form-group id="cap" :label="this.$t('registration.cap')" label-for="cap-input" class="m-input">
          <b-form-input id="cap-input" v-model="registration.invoice.data.postcode" type="number"
            :disabled="indirizzoCheck"></b-form-input>
        </b-form-group>

        <div class="o-registration-collapse__action">
          <b-button v-if="false" v-b-toggle="['accordion-privacy', 'accordion-fatturazione']" variant="hollow"
            class="text-primary" size="md">{{ $t("registration.salta") }}
          </b-button>
          <b-button v-b-toggle="['accordion-privacy', 'accordion-fatturazione']" variant="primary" size="md"
            :disabled="showCheck[2] !== true">{{ $t("registration.continua") }}
          </b-button>
        </div>
      </b-collapse>

      <div block :class="funtionCollapseStates(3)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{
          'o-registration-collapse__hide': registration.privacy !== 'true',
        }">
          <span class="icon-check"></span>
        </div>
        {{ $t("step.privacy") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[2] }" v-b-toggle="['accordion-privacy']"></button>
      </div>
      <b-collapse id="accordion-privacy" accordion="my-privacy" role="tabpanel" class="o-registration-collapse__form"
        v-model="collapseStates[3]">
        <p>
          <small><strong>{{ $t("step.privacy_1") }}</strong></small>
        </p>

        <div>
          <u>
            <p v-if="isApp" @click.prevent="openLink">{{
                $t("step.showPrivacy")
            }}</p>
            <a v-else :href="pdfLink" download="InformativaPrivacyPaziente">{{ $t("step.downloadPrivacy") }}</a>
          </u>
        </div>
        <div class="o-registration-collapse__radio">
          <p>
            <small>
              {{ $t("step.privacy_2") }} <br />
              <br><strong>{{ $t("step.cons_1") }}</strong></small>
          </p>
          <b-form-group>
            <b-form-radio-group id="radio-privacy" v-model="registration.privacy" name="privacy">
              <b-form-radio value="true">{{
                  $t("step.consenso_si")
              }}
              </b-form-radio>
              <b-form-radio value="false" v-if="false">{{
                  $t("step.consenso_no")
              }}
              </b-form-radio>
            </b-form-radio-group>

            <div v-if="false">
              <p>
                <small><br><strong>{{ $t("step.cons_2") }}</strong></small>
              </p>
              <b-form-radio-group id="radio-privacy-2" v-model="registration.privacy2" name="privacy-2">
                <b-form-radio value="true">{{
                    $t("step.consenso_si")
                }}
                </b-form-radio>
                <b-form-radio value="false">{{
                    $t("step.consenso_no")
                }}
                </b-form-radio>
              </b-form-radio-group>
            </div>

            <div v-if="false">
              <p>
                <small><br><strong>{{ $t("step.cons_3") }}</strong></small>
              </p>
              <b-form-radio-group id="radio-privacy-3" v-model="registration.privacy3" name="privacy-3">
                <b-form-radio value="true">{{
                    $t("step.consenso_si")
                }}
                </b-form-radio>
                <b-form-radio value="false">{{
                    $t("step.consenso_no")
                }}
                </b-form-radio>
              </b-form-radio-group>
            </div>

            <p>
              <small><br><strong>{{ $t("step.cons_4") }}</strong></small>
            </p>
            <b-form-radio-group id="radio-privacy-4" v-model="registration.privacy4" name="privacy-4">
              <b-form-radio value="true">{{
                  $t("step.consenso_si")
              }}
              </b-form-radio>
              <b-form-radio value="false">{{
                  $t("step.consenso_no")
              }}
              </b-form-radio>
            </b-form-radio-group>


            <p>
              <small><br><strong>{{ $t("step.cons_5") }}</strong></small>
            </p>
            <b-form-radio-group id="radio-privacy-5" v-model="registration.privacy5" name="privacy-5">
              <b-form-radio value="true">{{
                  $t("step.consenso_si")
              }}
              </b-form-radio>
              <b-form-radio value="false">{{
                  $t("step.consenso_no")
              }}
              </b-form-radio>
            </b-form-radio-group>

            <div v-if="false">
              <p>
                <small><br><strong>{{ $t("step.cons_6") }}</strong></small>
              </p>
              <b-form-radio-group id="radio-privacy-6" v-model="registration.privacy6" name="privacy-6">
                <b-form-radio value="true">{{
                    $t("step.consenso_si")
                }}
                </b-form-radio>
                <b-form-radio value="false">{{
                    $t("step.consenso_no")
                }}
                </b-form-radio>
              </b-form-radio-group>
            </div>

            <div v-if="false">
              <p>
                <small><br><strong>{{ $t("step.cons_7") }}</strong></small>
              </p>
              <b-form-radio-group id="radio-privacy-7" v-model="registration.privacy7" name="privacy-7">
                <b-form-radio value="true">{{
                    $t("step.consenso_si")
                }}
                </b-form-radio>
                <b-form-radio value="false">{{
                    $t("step.consenso_no")
                }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </b-form-group>
        </div>
        <div class="o-registration-collapse__action">
          <b-button v-b-toggle="['accordion-riepilogo', 'accordion-privacy']" @click="checkLast" variant="primary"
            :disabled="registration.privacy !== 'true' || registration.privacy4 === null || registration.privacy5 === null"
            size="md">{{ $t("step.completa") }}
          </b-button>
        </div>
      </b-collapse>

      <b-collapse id="accordion-riepilogo" accordion="riepilogo" class="o-registration-collapse__form mt-2">
        <div class="o-registration-collapse__info-box">
          <p class="o-registration-collapse__info-box--titolo ">
            {{ $t("step.riepilogo") }}
          </p>
          <hr />

          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.name") }}:</p>
            <p>
              <strong>{{ registration.firstName }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.surname") }}:</p>
            <p>
              <strong>{{ registration.lastName }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm" v-if="registration.codiceFiscale != null">
            <p>{{ $t("registration.cf") }}:</p>
            <p>
              <strong>{{ registration.codiceFiscale }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.birthdate") }}:</p>
            <p>
              <strong>{{ registration.birthDate }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.gender") }}:</p>
            <p>
              <strong>{{ registration.gender }}</strong>
            </p>
          </div>
          <div v-if="registration.straniero" class="m-card--info m-card--sm">
            <p>{{ $t("registration.nation_born") }}:</p>
            <p>
              <strong>{{ registration.nationBorn == null ? "ND" : getNation(registration.nationBorn).text }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm" v-if="registration.straniero == false">
            <p>{{ $t("registration.country_born") }}:</p>
            <p>
              <strong>{{ registration.country }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm" v-if="registration.straniero == false">
            <p>{{ $t("registration.city_born") }}:</p>
            <p>
              <strong>{{ registration.nationBorn == 999100 ? registration.cityBornFromTaxCode : "ND" }}</strong>
            </p>
          </div>
        </div>
        <div class="o-registration-collapse__info-box">
          <p class="o-registration-collapse__info-box--titolo ">
            {{ $t("step.riepilogo_credenziali") }}
          </p>
          <hr />

          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.nome_utente") }}:</p>
            <p>
              <strong>{{ registration.username }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.password") }}:</p>
            <p>
              <strong>{{ passwordHide }}</strong>
              <span @mousedown="passwordDown" @mouseup="passwordUp" class="icon-eye"></span>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.email") }}:</p>
            <p>
              <strong>{{ registration.email }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.tel") }}:</p>
            <p>
              <strong>{{ registration.tel }}</strong>
            </p>
          </div>
        </div>
        <div class="o-registration-collapse__info-box" v-if="this.registration.invoice.data.country !== undefined">
          <p class="o-registration-collapse__info-box--titolo ">
            {{ $t("step.riepilogo_fatturazione") }}
          </p>
          <hr />
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.nation_billing") }}:</p>
            <p>
              <strong>{{ registration.invoice.data.country != null ? getNation(registration.invoice.data.country).text :
                  "NA"
              }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.country_billing") }}:</p>
            <p>
              <strong>{{ registration.invoice.data.county }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.city") }}:</p>
            <p>
              <strong>{{ registration.invoice.data.city }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.address") }}:</p>
            <p>
              <strong>{{ registration.invoice.data.name }}</strong>
            </p>
          </div>
          <div class="m-card--info m-card--sm">
            <p>{{ $t("registration.cap") }}:</p>
            <p>
              <strong>{{ registration.invoice.data.postcode }}</strong>
            </p>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="text-center mt-3">
      <b-button variant="secondary" class="text-light text-uppercase mt-2 mb-2 ml-xl-2" v-if="!campiCompilati"
        @click="inviaRegistrazione">{{ $t("registration.action") }}
      </b-button>

      <b-modal id="errore-reg" centered modal-class="o-modal" content-class="o-modal__content"
        header-class="o-modal__header" dialog-class="o-modal__dialog"
        body-class="o-modal__body o-modal__body--response " ok-title="ok-title" :cancel-disabled="false"
        :hide-footer="true" :hide-header-close="false" :hide-header="false" size="lg">
        <div class="o-modal__body--icon-close">
          <span class="icon-lock"></span>
          <span class="icon-close text-light bg-warning"></span>
        </div>
        <p class="o-modal__body--title text-warning">
          <strong> Errore Registrazione </strong>
        </p>
        <p v-if="err === -1">{{ $t('registration.user_duplicate_error') }}</p>
        <p v-if="err === -2">{{ $t('registration.birth_date_invalid') }}</p>
        <p v-if="err === -3">{{ $t('registration.sanitarycode_invalid') }}</p>
      </b-modal>
    </div>

  </div>
</template>

<script>

import TaxCode from "codice-fiscale-js";
//import COUNTRIES from "@/assets/js/countries-it";
//import VueSimpleScrollbar from "vue-simple-scrollbar";
//import Places from "vue-places";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
import UTILS from "@/libs/utils.js";

export default {
  name: "AppRegistationStep",
  components: {
    //    VueSimpleScrollbar,
    //    Places,
  },
  //props: ["registration"],
  data() {
    return {
      registration: {
        firstName: null,
        lastName: null,
        codiceFiscale: null,
        straniero: false,
        nocodes: false,
        birthDate: "ND",
        gender: "ND",
        country: "ND",
        nationBorn: "ND",
        cityBornFromTaxCode: "ND",
        username: null,
        password: null,
        rePassword: null,
        email: null,
        reEmail: null,
        tel: null,
        invoice: {
          data: {
            country: null,
            county: null,
            city: null,
            name: null,
            postcode: null
          },
        },
        nation: null,
        province: null,
        privacy: null,
        privacy2: null,
        privacy3: null,
        privacy4: null,
        privacy5: null,
        privacy6: null,
        privacy7: null,
        team: "",
        stp: "",
        all: false,
      },

      isNazionale: true,

      err: 0,

      username_cf: "Il tuo Username sarà il tuo Codice Fiscale",
      username_st: "Scegli uno username per l'accesso",
      placeholder_username: "Il tuo Username sarà il tuo Codice Fiscale",

      anagrafica: false,


      pdfLink: require("@/assets/docs/InformativaPrivacyPazienti.pdf"),

      showPen: [false, false, false, false],
      showCheck: [false, false, false, false],
      disableButton: [false, true, true, true],
      collapseStates: [true, false, false, false],

      passwordHide: "*********",

      nation_options: null,
      province_options: null,
      city_options: null,

      optionsGender: [
        { value: "M", text: "Maschile" },
        { value: "F", text: "Femminile" },
      ],

      optionsNationBorn: [
        { value: "I", text: "Italia" },
        { value: "F", text: "Francia" },
      ],

      optionsCountry: [
        { value: "P", text: "Parigi" },
        { value: "R", text: "Roma" },
      ],

      optionsCityBornFromTaxCode: [
        { value: "V", text: "Chiari" },
        { value: "P", text: "Parigi" },
      ],

      optionProvince: [],
      optionComuni: [],
    };
  },
  computed: {
    isApp() {
      return this.$route.query.app
    },
    campiCompilati() {
      if (this.registration.all !== true) {
        return true;
      } else {
        return false;
      }
    },
    codiceFiscaleState() {
      if (
        this.isTaxCode(this.registration.codiceFiscale) ||
        this.registration.codiceFiscale == null
      ) {
        return null;
      } else {
        return false;
      }
    },
    indirizzoCheck() {
      return false;
      /*
      if (this.registration.invoice.data.country === undefined) {
        return true;
      } else {
        return false;
      }
      */
    },
  },
  created() {
    this.fillNations();
    this.fillProvince();
  },

  watch: {
    registration: {
      handler() {
        this.checkRegistration();
      },
      deep: true,
    },
  },

  methods: {
    openLink() {
      UTILS.postMessageToApp({ action: 'informativaPrivacyPaziente' })
    },
    decodeProv(prov) {
      if (this.isNazionale) {
        for (let i = 0; i < this.optionProvince.length; i++) {
          if (this.optionProvince[i].value == prov) {
            return this.optionProvince[i].text;
          }
        }
      }
      else
        return prov;

      return null;
    },

    fillNations() {
      let res = this.getCache("lookup/nazioni");
      if (res != null) {
        const nats = res;

        let tmpArr = []
        for (let i = 0; i < nats.length; i++) {
          const item = nats[i];
          const tmp = { value: item.codice, text: item.denominazione, alpha2: item.alpha2, catasto: item.catasto };
          tmpArr.push(tmp);

        }
        this.optionsNationBorn = tmpArr;
        return;
      }
      apiAxios.get("lookup/nazioni")
        .then(response => {

          const nats = response.data.getNazioniResult;

          let tmpArr = []
          for (let i = 0; i < nats.length; i++) {
            const item = nats[i];
            const tmp = { value: item.codice, text: item.denominazione, alpha2: item.alpha2, catasto: item.catasto };
            tmpArr.push(tmp);

          }
          this.optionsNationBorn = tmpArr;

          this.setCache("lookup/nazioni", nats)
        })
    },

    getWiredProvs()
    {
      let res=[];
      for(let i =0;i<this.optionProvince.length;i++)
      {
        res.push(this.optionProvince[i]);
      }
      res.push ({ value: 'PL', text: 'POLA' });
      return res;
    },
    fillProvince() {
      let res = this.getCache("lookup/province");
      if (res != null) {
        const nats = res;

        let tmpArr = []
        for (let i = 0; i < nats.length; i++) {
          const item = nats[i];
          const tmp = { value: item.prov, text: item.provincia };
          tmpArr.push(tmp);

        }
        this.optionProvince = tmpArr;
        return;
      }
      apiAxios.get("lookup/province")
        .then(response => {

          const nats = response.data.ProvinceResult;

          let tmpArr = []
          for (let i = 0; i < nats.length; i++) {
            const item = nats[i];
            const tmp = { value: item.prov, text: item.provincia };
            tmpArr.push(tmp);

          }
          this.optionProvince = tmpArr;

          this.setCache("lookup/province", nats)
        })
    },

    fillComuni(prov) {
      this.registration.invoice.data.city = '';

      let res = this.getCache("lookup/comuni/" + prov);
      if (res != null) {
        const nats = res;

        let tmpArr = []
        for (let i = 0; i < nats.length; i++) {
          const item = nats[i];
          //const tmp = {value:item.codice, text:item.comune};
          const tmp = { value: item.comune, text: item.comune };
          tmpArr.push(tmp);

        }
        this.optionComuni = tmpArr;
        return;
      }
      apiAxios.get("lookup/comuni/" + prov)
        .then(response => {

          const nats = response.data.ComuniResult;

          let tmpArr = []
          for (let i = 0; i < nats.length; i++) {
            const item = nats[i];
            //const tmp = {value:item.codice, text:item.comune};
            const tmp = { value: item.comune, text: item.comune };
            tmpArr.push(tmp);

          }
          this.optionComuni = tmpArr;

          this.setCache("lookup/comuni/" + prov, nats)
        })
    },

    changeNation(val) {
      let newNaz;

      if (val == 999100) {
        newNaz = true;

        this.registration.invoice.data.county = null;
        this.registration.invoice.data.city = null;
        this.registration.invoice.data.name = null;
        this.registration.invoice.data.postcode = null;
      }
      else {
        newNaz = false;

        this.registration.invoice.data.county = 'EE';
        this.registration.invoice.data.city = null;
        this.registration.invoice.data.name = null;
        this.registration.invoice.data.postcode = '00000';
      }

      this.isNazionale = newNaz;
    },

    // Vocali di una stringa
    extractVowels(str) {
      return str.replace(/[^AEIOU]/gi, '')
    },

    // Consonanti di una stringa
    extractConsonants(str) {
      return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '')
    },

    fixDate(data) {
      //console.log(data);
      return data;
      // const r=data.substring(6,10)+"-"+data.substring(3,5)+"-"+data.substring(0,2);
      // return r;
    },

    cittaFromCF(cf) {
      if (cf == null)
        return null;
      if (cf.leading < 16)
        return null;
      return cf.substring(11, 15);
    },

    /**
     * Build the object containing the user informations for the registration, based on the country, if Italy all information is collected, otherwise cap, citta and provincia are removed
     * @param {Object} data element containing all the information inserted during registration
     * @returns object containing user info for registration
     */
    getDataToSend(data) {
      let postData
      if (data.invoice.data.country == 999100) {
        postData = {
          cap: data.invoice.data.postcode,
          cellulare: data.tel,
          citta: data.invoice.data.city == null ? '' : data.invoice.data.city,
          codiceFiscale: data.codiceFiscale,
          cognome: data.lastName,
          comune_nascita: this.cittaFromCF(data.codiceFiscale),
          cons: [data.privacy, data.privacy2, data.privacy3, data.privacy4, data.privacy5, data.privacy6, data.privacy7],
          dtNascita: this.fixDate(data.birthDate),
          indirizzo: data.invoice.data.name,
          email: data.email,
          nazionalita: data.nationBorn,
          nazione: data.invoice.data.country,
          nocodes: data.nocodes,
          nome: data.firstName,
          provincia: this.decodeProv(data.invoice.data.county),
          password: data.password,
          sesso: data.gender,
          stp: data.stp,
          team: data.team,
          urlFail: document.location.origin + "/attivazioneUtenteLink",
          urlSuccess: document.location.origin + "/attivazioneUtenteLink",
          username: data.username
        }
      } else {
        postData = {
          cap: "",
          cellulare: data.tel,
          citta: "",
          codiceFiscale: data.codiceFiscale,
          cognome: data.lastName,
          comune_nascita: this.cittaFromCF(data.codiceFiscale),
          cons: [data.privacy, data.privacy2, data.privacy3, data.privacy4, data.privacy5, data.privacy6, data.privacy7],
          dtNascita: this.fixDate(data.birthDate),
          indirizzo: data.invoice.data.name,
          email: data.email,
          nazionalita: data.nationBorn,
          nazione: data.invoice.data.country,
          nocodes: data.nocodes,
          nome: data.firstName,
          password: data.password,
          sesso: data.gender,
          stp: data.stp,
          team: data.team,
          urlFail: document.location.origin + "/attivazioneUtenteLink",
          urlSuccess: document.location.origin + "/attivazioneUtenteLink",
          username: data.username
        }
      }
      return postData
    },

    inviaRegistrazione() {
      const data = this.registration;
      let postData = this.getDataToSend(data);

      apiAxios.post("account/register", postData)
        .then(response => {
          const resError = response.data.RegisterError;
          if (resError < 0) {
            this.err = resError;
            this.$bvModal.show("errore-reg");
            return;
          }
          const resData = response.data.RegisterResult;
          sessionStorage.clear();

          let params = ""
          console.log(this.$route.query)
          if (this.$route.query.app) {
            params += "app=1&"
          }
          // params += "data=" + Buffer.from(JSON.stringify(resData)).toString("base64")
          params += "data=" + btoa(JSON.stringify(resData))
          router.push("emailRegistrazione?" + params);
          // router.push("emailRegistrazione?data=" + btoa(JSON.stringify(resData)));

        })
        .catch((error) => {
          console.log(error)
          if (error.data != undefined) {
            const resError = error.data.code;

            if (resError < 0) {

              this.err = resError;
              this.$bvModal.show("errore-reg");
              return;
            }
          } else {
            this.$bvModal.show("errore-reg");
          }
        })
    },

    getCache(name) {
      const key = "cache@" + name.replace(/\//g, '@');

      const cache = localStorage.getItem(key)

      if (cache == null)
        return null;

      const val = JSON.parse(cache);

      return val;
    },

    setCache(name, value) {
      const key = "cache@" + name.replace(/\//g, '@');

      localStorage.setItem(key, JSON.stringify(value))

    },

    getNationCodeByCatasto(key) {
      for (let i = 0; i < this.optionsNationBorn.length; i++) {
        const opt = this.optionsNationBorn[i];
        if (opt.catasto === key || opt.alpha2 === key) {

          return opt.value
        }

      }
      return null;

    },

    getNation(key) {
      for (let i = 0; i < this.optionsNationBorn.length; i++) {
        const opt = this.optionsNationBorn[i];
        if (opt.value === key || opt.alpha2 === key) {

          return opt
        }

      }
      return { "text": "ND" };

    },

    funtionCollapseStates($id) {
      if (this.collapseStates[$id] == false) {
        if (this.showCheck[$id] == true) {
          return "o-registration-collapse__btn o-registration-collapse__btn--close o-registration-collapse__btn--cheked";
        } else {
          return "o-registration-collapse__btn o-registration-collapse__btn--close";
        }
      } else {
        return "o-registration-collapse__btn o-registration-collapse__btn--open";
      }
    },

    passwordDown() {
      this.passwordHide = this.registration.password;
    },

    passwordUp() {
      this.passwordHide = "*********";
    },

    isTaxCode(taxcode) {
      const regExp = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
      if (taxcode != null && taxcode != "")
        return regExp.test(taxcode);
      return true;
    },

    checkLast() {
      this.registration.all = true;
      //console.log(this.registration.all);
    },

    checkRegistration() {
      //Anagrafica

      let check1 = (
        this.registration.firstName != "" &&
        this.registration.firstName != null &&
        (this.isNameValid(this.registration.firstName, this.registration.codiceFiscale) == null) &&
        this.registration.lastName != "" &&
        this.registration.lastName != null &&
        (this.isSurnameValid(this.registration.lastName, this.registration.codiceFiscale) == null));

      let check2 = false;
      let check3 = false;
      let check4 = false;
      if (check1) {
        if (this.registration.straniero === "true") {
          this.placeholder_username = this.username_st;
          if (this.registration.nocodes === "true") {
            check2 = this.registration.birthDate !== "" && this.registration.birthDate !== "ND";
          }
          else {
            check2 = this.registration.birthDate !== "" && this.registration.birthDate !== "ND"
              && ((this.registration.stp !== "" && this.isSTP(this.registration.stp) == null) ||
                (this.registration.team !== '' && this.isTeam(this.registration.team) == null));
          }

          check2 = check2 && (this.registration.nationBorn !== "ND" && this.registration.gender !== "ND");
        }
        else {
          check2 = this.isTaxCode(this.registration.codiceFiscale) && (this.registration.codiceFiscale + "" !== "") && (this.registration.codiceFiscale != null);
          //console.log(this.isTaxCode(this.registration.codiceFiscale));
          //console.log(this.registration.codiceFiscale+""!=="");
          //console.log(this.registration.codiceFiscale !=null);
          //console.log(check2);

          this.autoFillByTaxCode();
          this.placeholder_username = this.username_cf;
          this.nationBorn = 999100;
        }

      }
      else {
        this.registration.birthDate = 'ND';
        this.registration.gender = 'ND';
        this.registration.nationBorn = 'ND';
        this.registration.country = 'ND';
        this.registration.cityBornFromTaxCode = 'ND';
        this.registration.username = '';
      }

      if (check2) {

        check3 = (this.registration.username !== null)
          && (this.registration.password !== null)
          && (this.isPassword(this.registration.password) == null)
          && (this.isEmail(this.registration.email) == null)
          && (this.registration.email === this.registration.reEmail)
          && (this.isPhone(this.registration.tel) == null)
          && (this.registration.tel !== null);

      }

      if (check3) {
        check4 = this.registration.invoice.data.country !== undefined &&
          this.registration.invoice.data.county !== undefined &&
          this.registration.invoice.data.city !== undefined &&
          this.registration.invoice.data.name !== undefined &&
          this.registration.invoice.data.postcode !== undefined &&
          this.registration.invoice.data.country !== null &&
          this.registration.invoice.data.county !== null &&
          this.registration.invoice.data.city !== null &&
          this.registration.invoice.data.name !== null &&
          this.registration.invoice.data.postcode !== null &&
          this.registration.invoice.data.country !== "" &&
          this.registration.invoice.data.county !== "" &&
          this.registration.invoice.data.city !== "" &&
          this.registration.invoice.data.name !== "" &&
          this.registration.invoice.data.postcode !== "" &&
          (this.registration.invoice.data.country == 999100 ? this.isCAP(this.registration.invoice.data.postcode) : true);
      }

      console.log(this.registration.invoice.data.country);
      console.log(this.registration.invoice.data.county + ' | ' + this.decodeProv(this.registration.invoice.data.county));
      console.log(this.registration.invoice.data.city);
      console.log(this.registration.invoice.data.name);
      console.log(this.registration.invoice.data.postcode);

      this.showCheck[0] = check2;

      //console.log(this.showCheck[0]);

      this.showPen[0] = check2
      this.showCheck[1] = check3;
      this.showPen[1] = check3;
      this.showCheck[2] = check4;
      this.showPen[2] = check4;

      /*
      this.showCheck[1] = false;
      if (
          this.registration.firstName != "" &&
          this.registration.firstName != null &&
          (this.isName(this.registration.firstName) == null) &&
          this.registration.lastName != "" &&
          this.registration.lastName != null &&
          (this.isName(this.registration.lastName) == null)
      ) {
        if (this.isTaxCode(this.registration.codiceFiscale) && (this.registration.codiceFiscale+""!=="")) {
          this.showPen[0] = true;
          this.showCheck[0] = true;
          this.showCheck[1] = false;
          this.showPen[1] = false;

          this.autoFillByTaxCode();
        } else {
          if (this.registration.straniero === "true") {

            this.placeholder_username = this.username_st;
            if (this.registration.nocodes !== "true") {
              if(this.registration.birthDate!=='' && (this.registration.stp!==''||this.registration.team!==''))
              {
                this.showPen[0] = true;
                this.showCheck[0] = true;
                this.showCheck[1] = false;
                this.showCheck[1] = false;
                this.showPen[1] = false;
              }
            }
            else {
              if(this.registration.birthDate!=='' )
              {
                this.showPen[0] = true;
                this.showCheck[0] = true;
                this.showCheck[1] = false;
                this.showCheck[1] = false;
                this.showPen[1] = false;
              }
            }
          } else {
            this.showPen[0] = true;
            this.showCheck[0] =  (this.registration.codiceFiscale+""!=="");
            this.showCheck[1] = false;
            this.showPen[1] = false;
            this.placeholder_username = this.username_cf;
            this.nationBorn = 999100;
          }
        }
      } else {
        this.showCheck[0] = false;
        this.showCheck[1] = false;
        this.showPen[1] = false;
      }

      if (
          this.registration.username != "" &&
          this.registration.username != null
      ) {
        this.showPen[1] = false;
        this.showCheck[1] = false;

        if (this.isPassword(this.registration.password) == null) {
          this.showPen[1] = true;
          this.showCheck[1] = true;

          if (this.registration.password !== null) {
            if (this.isEmail(this.registration.email) == null) {
              if (this.registration.email === this.registration.reEmail) {
                if (
                    this.isPhone(this.registration.tel) == null &&
                    this.registration.tel !== null
                ) {
                  this.showCheck[1] = true;
                } else {
                  this.showCheck[1] = false;
                }
              } else {
                this.showCheck[1] = false;
              }
            } else {
              this.showCheck[1] = false;
            }
          } else {
            this.showCheck[1] = false;
          }
        } else {
          this.showPen[1] = true;
          this.showCheck[1] = false;
        }
      } else {
        this.showCheck[1] = false;
      }

      if (this.registration.invoice.data.country === undefined ||
          this.registration.invoice.data.county === undefined ||
          this.registration.invoice.data.city === undefined ||
          this.registration.invoice.data.name === undefined ||
          this.registration.invoice.data.postcode === undefined ||
          this.registration.invoice.data.country === "" ||
          this.registration.invoice.data.county === "" ||
          this.registration.invoice.data.city === "" ||
          this.registration.invoice.data.name === "" ||
          this.registration.invoice.data.postcode === "") {
        this.showPen[2] = false;
        this.showCheck[2] = false;
      } else {
        this.showPen[2] = true;
        this.showCheck[2] = true;
      }

       */
      //console.log(this.registration);
    },

    isPhone(phone) {
      // const regExp = /^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/g;
      const regExp = /^(\+|\d)[0-9]{7,16}$/g;
      if (phone != null && phone != "") {
        if (regExp.test(phone) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    isTeam(team) {
      const regExp = /^80\d{17,18}$/g;
      if (team != null && team != "") {
        if (regExp.test(team) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    isSTP(stp) {
      const regExp = /^(STP|ENI)\d{13}$/g;
      if (stp != null && stp != "") {
        if (regExp.test(stp) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    isEmail(email) {
      const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
      if (email != null) {
        if (regExp.test(email) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    /*
    isName(name) {
      //const regExp = /^[a-zA-Z\s']*$/;
      const regExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ']+$/u
      if (name != null) {
        if (regExp.test(name) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    */

    isSurnameValid(surname, cf) {
      if (cf === "" || surname === "" || cf === null || surname === null) return null;
      //console.log(surname);
      //console.log(cf.substr(0,3).toUpperCase()); 
      if (this.surnameCode(surname) == cf.substr(0, 3).toUpperCase())
        return null;
      else
        return false;
    },

    isNameValid(name, cf) {
      if (cf === "" || name === "" || cf === null || name === null) return null;
      //console.log(name);
      //console.log(cf.substr(3,3).toUpperCase()); 
      if (this.nameCode(name) == cf.substr(3, 3).toUpperCase())
        return null;
      else
        return false;
    },

    // Lettere cognome per CF
    surnameCode(surname) {
      var codeSurname = this.extractConsonants(surname) + this.extractVowels(surname) + "XXX";
      //console.log(codeSurname.substr(0, 3).toUpperCase());
      return codeSurname.substr(0, 3).toUpperCase();
    },

    // Lettere nome per CF
    nameCode(name) {
      var codNome = this.extractConsonants(name);
      if (codNome.length >= 4) {
        codNome = codNome.charAt(0) + codNome.charAt(2) + codNome.charAt(3);
      } else {
        codNome += this.extractVowels(name) + "XXX";
        codNome = codNome.substr(0, 3);
      }
      //console.log(codNome.toUpperCase());
      return codNome.toUpperCase();
    },

    chengeTypeInput(id) {
      if (document.getElementById(id).type !== "text") {
        document.getElementById(id).type = "text";
      } else {
        document.getElementById(id).type = "password";
      }
    },

    isPassword(password) {
      const regExp = /^(?=.*[A-Za-z]+)(?=.*\d)[A-Za-z\d\\!"£$%&/()=?^*#.:,;-_<>]{8,}$/;
      if (password != null) {
        if (regExp.test(password) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    /**
     * Returns a Boolean value that indicates if the input parameter is exactly a 5 digit input, representing a valid italian CAP
     * @param {String} cap input value for CAP
     */
    isCAP(cap) {
      const regExp = new RegExp("^\\d{5}$", "g")
      return regExp.test(cap)
    },

    autoFillByTaxCode() {

      if (this.registration.codiceFiscale == null || this.registration.codiceFiscale === "" || !this.isTaxCode(this.registration.codiceFiscale)) {
        this.registration.birthDate = 'ND';
        this.registration.gender = 'ND';
        this.registration.nationBorn = 'ND';
        this.registration.country = 'ND';
        this.registration.cityBornFromTaxCode = 'ND';
        this.registration.username = '';
        return;
      }

      let taxCode = null;
      try {
        taxCode = new TaxCode(
          this.registration.codiceFiscale.toUpperCase()
        );
      } catch (err) {
        return;
      }

      //console.log(taxCode);
      const birthdate = {
        day: taxCode.day < 10 ? `0${taxCode.day}` : taxCode.day,
        month: taxCode.month < 10 ? `0${taxCode.month}` : taxCode.month,
        year: taxCode.year,
      };

      this.registration.gender = taxCode.gender;
      this.registration.birthDate = `${birthdate.day}/${birthdate.month}/${birthdate.year}`;
      if (taxCode.birthplace.prov === 'EE') {
        this.registration.nationBorn = this.getNationCodeByCatasto(taxCode.birthplace.cc);
      }
      else {
        this.registration.nationBorn = 999100;
      }

      //provincia
      const provs=this.getWiredProvs();

      let country = provs.find((actual) => {
        return actual.value == taxCode.birthplace.prov;
      });
      if (country!=null) {
        this.registration.country = country.text;

      this.registration.cityBornFromTaxCode = taxCode.birthplace.nome; //not directly in model CityBorn, watch on change of CountryBorn reset CityBorn LIST, so set city born only after that
      }
      this.registration.username = this.registration.codiceFiscale;
    },
  },
};
</script>
