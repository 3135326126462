<template>
  <div class="action-registration">
    <div class="a-margin-xl row">
      <div class="col-12 m-card">
        <h1 class="o-heading o-heading--xl mt-3">
          {{ $t("login.message_3") }}
        </h1>
        <p>
          {{ $t("registration.message_0") }}
        </p>

        <p class="mt-2 mb-3">
          <strong>{{ $t("registration.message_1") }}</strong>
        </p>
      </div>
      <div class="col-12 pt-3 d-none d-lg-block">
        <b-button
          variant="outline-primary"
          class="text-uppercase mt-2 ml-xl-2"
          :to="{ path: '/' }"
          >{{ $t("registration.noaction") }}</b-button
        >
        <b-button
          variant="secondary"
          class="text-light text-uppercase mt-2 ml-xl-2"
          v-if="false /*!campiCompilati*/"
          @click="inviaRegistrazione"
          >{{ $t("registration.action") }}</b-button
        >
      </div>
    </div>
    <b-modal v-if="false"
             id="errore-reg"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="false"
             :hide-header="false"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-lock"></span>
        <span class="icon-close text-light bg-warning"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Errore Registrazione </strong>
      </p>
      <p v-if="err===-1">{{$t('registration.user_duplicate_error')}}</p>
      <p v-if="err===-2">{{$t('registration.birth_date_invalid')}}</p>
      <p v-if="err===-3">{{$t('registration.sanitarycode_invalid')}}</p>


    </b-modal>
  </div>
</template>

<script>
//import apiAxios from "@/libs/apiAxios";
//import router from "@/router";

export default {
  name: "AppRegistration",
  props: ["registrationData"],

  data() {
    return {
      err:0
    };
  },
  computed: {
    /*campiCompilati() {
      if (this.registrationData.all !== true) {
        return true;
      } else {
        return false;
      }
    },*/
  },

  methods: {
    /*
    fixDate(data)
    {
      console.log(data);
      return data;
      // const r=data.substring(6,10)+"-"+data.substring(3,5)+"-"+data.substring(0,2);
      // return r;
    },
    cittaFromCF(cf)
    {
      if (cf==null)
        return null;
      if (cf.leading<16)
        return null;
      return cf.substring(11,15);
    },

    inviaRegistrazione()
    {

      const data=this.registrationData;
      console.log(data);
      if ('oCountry' in data.invoice.data)
      {
        data.invoice.data.countryCode = data.invoice.data.oCountry.value;
      }

      const postData={
        username: data.username,
        dtNascita: this.fixDate(data.birthDate),
        email:data.email,
        comune_nascita: this.cittaFromCF(data.codiceFiscale),
        nazionalita:data.nationBorn,
        nome: data.firstName,
        cognome: data.lastName,
        cellulare:data.tel,
        sesso:data.gender,
        citta:data.invoice.data.city==null?'':data.invoice.data.city,
        codiceFiscale:data.codiceFiscale,
        provincia:data.invoice.data.county,
        indirizzo:data.invoice.data.name,
        nazione:data.invoice.data.countryCode,
        cap:data.invoice.data.postcode,
        team:data.team,
        stp:data.stp,
        nocodes:data.nocodes,
        password:data.password,
        urlSuccess:document.location.origin+"/attivazioneUtenteLink",
        urlFail:document.location.origin+"/attivazioneUtenteLink",
        cons:[data.privacy, data.privacy2,data.privacy3,data.privacy4,data.privacy5,data.privacy6,data.privacy7]
      }

      apiAxios.post("account/register",postData)
          .then(response => {
            const resError= response.data.RegisterError;


            if (resError<0)
            {

              this.err=resError;
              this.$bvModal.show("errore-reg");
              return;
            }
            const resData= response.data.RegisterResult;
            sessionStorage.clear();
              router.push("emailRegistrazione?data="+btoa(JSON.stringify(resData)));

          })
          .catch((error)=>{
            const resError=error.data.code;

            if (resError<0)
            {

              this.err=resError;
              this.$bvModal.show("errore-reg");
              return;
            }
          })
    }
    */
  },
};
</script>
